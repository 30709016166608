import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
  generatePDF,
  generateTrialBalance,
  generateLedgerBalance,
  generateExcelLedgerBalance,
  billSimpleData,
} from "utils/reportGenerator";
import { getSessionData } from "utils/common";
import axios from "axios";
import { showMessage } from "./common.slice";
import _ from "lodash";
import { generateExcel } from "utils/reportGenerator";
import { exportToExcel } from "utils/reportGenerator";

const initialState = {
  sortReportLedgerArr: [],
  ledgerReportList: [],
  ledgerReportLoading: false,
  isReportGet: false,
  tradeReportFilterValue: {},
  OTDReportLogList: [],
  OTDReportLogLoading: false,
  OTDReportLogTotal: 0,
  OTDReportLogLimit: 10,
  OTDReportLogCurrentPage: 1,
  OTDReportLogCurrentSearchText: "",
  startApiCallOTDReportLogList: true,
  filterDataUpdateLog: {
    tradeType: "1",
    segmentId: "",
    userId: "",
    startDate: new Date(),
    endDate: new Date(),
  },
  isFilterDataUpdateLog: false,

  OTRejectionLogList: [],
  OTRejectionLogLoading: false,
  OTDRejectionLogTotal: 0,
  OTDRejectionLogLimit: 10,
  OTDRejectionLogCurrentPage: 1,
  OTDRejectionLogCurrentSearchText: "",
  startApiCallOTDRejectionLogList: true,
  filterRejectionLogData: {
    tradeType: "1",
    segmentId: "",
    userId: "",
    startDate: new Date(),
    endDate: new Date(),
  },
  isFilterRejectionLogData: false,
  trialBalanceUpdated: false,
  tradeReportLoading: false,
  trialBalanceReportLoading:false,
  OpenModalTradeData: false,
  tradesbySymbolList:[],
  tradesbySymbolLoading:false,
  totalTradesbySymbol:0,
  openTradeData: {},

};

let aligh = { halign: "right", fontStyle: "bold" };
let bgGray = { fillColor: [132, 132, 132], textColor: [255, 255, 255] };
let textBlue = { textColor: [62, 62, 180], ...aligh, fontStyle: "bold" };
let textRed = { textColor: [245, 54, 92], ...aligh, fontStyle: "bold" };
let footerStyle = { ...bgGray, ...aligh, fontStyle: "bold" };
let footerBlue = { fillColor: [242, 243, 165], ...textBlue, fontStyle: "bold" };
let footerRed = { fillColor: [242, 243, 165], ...textRed, fontStyle: "bold" };

export const getLedgerReportList = createAsyncThunk(
  "/report/ledegerReport",
  (data) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let finalObject = {};
      let fromToLabel = ''
      if (data.userType && data.userType.value == 3) {
        finalObject.userId = userId;
      } else {
        finalObject.userId = data && data.userId ? data.userId.value : userId;
      }
      finalObject.isDateFilter =
        data && data.isDateFilter ? data.isDateFilter : false;
      finalObject.startDate =
        data && data.startDate ? data.startDate : moment().format();
      finalObject.endDate =
        data && data.endDate ? data.endDate : moment().format();
      finalObject.filter =
        data && data.filter ? data.filter.value : 0;
      fromToLabel = `From ${moment(data.startDate).format(
          "DD-MM-YYYY"
        )} To ${moment(data.endDate).format("DD-MM-YYYY")}`;
      axios
        .post("reports/ledger-report/get-ledger-report", finalObject)
        .then((res) => {
          if (res.data.status == 200) {
            if(res?.data?.data?.ledgerReportResponseModelList?.length > 0){
              let dummyData = [...res.data.data.ledgerReportResponseModelList]
              if(data.reportType == 2){
                const column = [
                  {
                    title: "Date",
                    dataKey: "date",
                  },
                  {
                    title: "Sr. No.",
                    dataKey: "srNo",
                  },
                  {
                    title: "Particulars",
                    dataKey: "particulars",
                  },
                  {
                    title: "Debit",
                    dataKey: "debitAmount",
                    styles: aligh,
                  },
                  {
                    title: "Credit",
                    dataKey: "creditAmount",
                    styles: aligh,
                  },
                  {
                    title: "Balance",
                    dataKey: "balance",
                    styles: aligh,
                  },
                  {
                    title: "Dr/Cr",
                    dataKey: "crDr",
                  },
                  {
                    title: "Description",
                    dataKey: "document",
                  },
                ]
                generateLedgerBalance({
                  FileName: "Ledger_Balance",
                  title: "Ledger Balance",
                  columnData: column,
                  dataArray: dummyData,
                  theme: "grid",
                  fromTo: fromToLabel,
                  headStyles: bgGray,
                  footerStyle: footerStyle,
                  clientName: data?.userId?.label ? data.userId.label : 'Client Name',
                  footerColorBlue: footerBlue,
                  footerColorRed: footerRed,
                  showHead: "showHead",
                });
              }
              if(data.reportType==3 && dummyData?.length > 0) {
                  generateExcelLedgerBalance({
                    responseData:dummyData,
                    labelName:"Ledger_Balance",
                    reportTitle:"Ledger_Balance",
                    fromToLabel:fromToLabel,
                    accountTypeLabel:data?.userId?.label ? data.userId.label : 'Client Name'
                  });
              }
              resolve(res.data.data.ledgerReportResponseModelList);
            }else{
              resolve([])
            }
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getTrialBalance = createAsyncThunk(
  "/report/trialBalance",
  (data) => {
    return new Promise((resolve, reject) => {
      let finalObject = {};
      const isSubmit = data.isSubmit
      finalObject.userId = data?.subBrokerId?.value;
      if (data.dateFilter === "1") {
        finalObject.startDate = moment().format();
        finalObject.endDate = moment().format();
      } else {
        finalObject.startDate =
          data && data.startDate ? data.startDate : moment().format();
        finalObject.endDate =
          data && data.endDate ? data.endDate : moment().format();
      }
      finalObject.dateFilter = data && data.dateFilter ? data.dateFilter : 1;
      // finalObject.subBrokerId = data.subBrokerId ? data.subBrokerId.value : "";
      finalObject.orderBy = data.orderBy ? data.orderBy.value : 1;
      finalObject.filterBy = data.filterBy ? data.filterBy.value : 1;
      finalObject.withZero = data.withZero ? data.withZero : false;
      let columnCredit = [
        {
          title: "Client Code",
          dataKey: "userCode",
        },
        {
          title: "Particulars",
          dataKey: "userName",
        },
        {
          title: "Credit",
          dataKey: "balance",
          styles: aligh,
        },
      ];

      let columnDebit = [
        {
          title: "Client Code",
          dataKey: "userCode",
        },
        {
          title: "Particulars",
          dataKey: "userName",
        },
        {
          title: "Debit",
          dataKey: "balance",
          styles: aligh,
        },
      ];

      let column = [
        {
          title: "Client Code",
          dataKey: "userCode",
        },
        {
          title: "Particulars",
          dataKey: "userName",
        },
        {
          title: "Debit",
          dataKey: "Dr",
          styles: aligh,
        },
        {
          title: "Credit",
          dataKey: "Cr",
          styles: aligh,
        },
      ];
      axios
        .post("reports/trial-balance-report/get-trial-balance", finalObject)
        .then(({ data: dataRep }) => {
          if (dataRep.status == 200) {
            if (dataRep.data?.length > 0) {
              if (isSubmit === "PDF") {
                generateTrialBalance({
                  reportType: data.isTFormatReport,
                  FileName: "Trial_Balance",
                  title: "Trial Balance",
                  column: column,
                  dataArray: dataRep.data,
                  columnDebit,
                  columnCredit,
                  rowPageBreak: true,
                  theme: "grid",
                  headStyles: bgGray,
                  footerStyle: footerStyle,
                  footerColorBlue: footerBlue,
                  footerColorRed: footerRed,
                  showHead: "showHead",
                });
              } else if (isSubmit === "EXCEL") {
                let dummyArray = []
                dataRep.data.forEach((item) => {
                  if (item?.data) {
                    let debitAmount = 0
                    let creditAmount = 0
                    item.data.forEach((item2, index) => {
                      debitAmount += item2.crDr === "Dr" ? item2.balance : 0
                      creditAmount += item2.crDr === "Cr" ? item2.balance : 0
                      dummyArray.push({
                        Authority: index === 0 ? item.name : '',
                        "Client Code": item2.userCode,
                        Particulars: item2.userName,
                        Debit: item2.crDr === "Dr" ? item2.balance : 0,
                        Credit: item2.crDr === "Cr" ? item2.balance : 0,
                      })
                      if (index === (item.data.length - 1)) {
                        dummyArray.push({ Authority: '', "Client Code": '', Particulars: '', Debit: '', Credit: '' })
                        dummyArray.push({
                          Authority: '', "Client Code": '',
                          Particulars: `Total : ${item.data.length}`, Debit: debitAmount, Credit: creditAmount
                        })
                        dummyArray.push({ Authority: '', "Client Code": '', Particulars: '', Debit: '', Credit: '' })
                      }
                    })
                  }
                });
                if (dummyArray?.length > 0) {
                  exportToExcel({
                    apiData: dummyArray,
                    fileName: "Trial Balance",
                  });
                }
              }
            }
            resolve(data.data);
          } else {
            reject(dataRep);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getOTDReportLogList = createAsyncThunk(
  "/report/orderTradeDeleteLogReport",
  ({ data, searchText, pageNo, pageSize, appendData }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.tradeType = Number(data.tradeType);
      newFinalObject.segmentId = data.segmentId ? data.segmentId.value : null;
      newFinalObject.userId = data.userId ? Number(data.userId.value) : userId;
      newFinalObject.pageNo = pageNo;
      newFinalObject.pageSize = pageSize;
      newFinalObject.startDate = data.startDate;
      newFinalObject.endDate = data.endDate;
      newFinalObject.searchText = searchText;
      axios
        .post(`reports/operation-logs/get-operation-logs`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getOTRejectionLogList = createAsyncThunk(
  "/report/orderTradeRejectionLogReport",
  ({ data, searchText, pageNo, pageSize, appendData }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.tradeType =
        data.tradeType && Number(data.tradeType) ? Number(data.tradeType) : 1;
      newFinalObject.segmentId = data.segmentId ? data.segmentId.value : null;
      newFinalObject.userId = data.userId ? Number(data.userId.value) : userId;
      newFinalObject.pageNo = pageNo;
      newFinalObject.pageSize = pageSize;
      newFinalObject.startDate = data.startDate
        ? data.startDate
        : moment().format();
      newFinalObject.endDate = data.endDate ? data.endDate : moment().format();
      newFinalObject.searchText = searchText;
      axios
        .post(`reports/rejection-logs/get-rejection-logs`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getTradesbySymbolList = createAsyncThunk(
  "/report/get-trades-by-symbol",
  ({data,appendData}) => {
    return new Promise((resolve, reject) => {
      let newFinalObject = {};
      newFinalObject.segmentId = data.segmentId;
      newFinalObject.userId = data.userId;
      newFinalObject.pageNo = 1;
      newFinalObject.pageSize =10000;
      newFinalObject.startDate = data.startDate;
      newFinalObject.endDate = data.endDate;
      newFinalObject.closeId = data.closeId;
      newFinalObject.symbol = data.symbol;
      axios
        .post(`trade/get-trades-by-symbol`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

// const pdfData = [
//   {
//     user: "(delta08) delta08",
//     data: [{
//       id:1,
//       script: "AARTIIND 28APR2022",
//       buyQty: { content: 25, styles: aligh },
//       buyAvg: { content: 25, styles: aligh },
//       sellQty: { content: 25, styles: aligh },
//       sellAvg: { content: 25, styles: aligh },
//       postQty: { content: 25, styles: textBlue },
//       ltp: { content: 25, styles: aligh },
//       m2m: { content: 25, styles: textBlue},
//     },
//     {
//       id:2,
//       script: "ASHOKLEY 28APR2022",
//       buyQty: { content: 25, styles: aligh },
//       buyAvg: { content: 25, styles: aligh },
//       sellQty: { content: 25, styles: aligh },
//       sellAvg: { content: 25, styles: aligh },
//       postQty: { content: 25, styles: textBlue },
//       ltp: { content: 25, styles: aligh },
//       m2m: { content: 25, styles: textBlue }
//     },
//   ]
//   },
//   {
//     user: "(delta07) delta07",
//     data: [{
//       id:1,
//       script: "AARTIIND 28APR2022",
//       buyQty: { content: 25, styles: aligh },
//       buyAvg: { content: 25, styles: aligh },
//       sellQty: { content: 25, styles: aligh },
//       sellAvg: { content: 25, styles: aligh },
//       postQty: { content: 25, styles: textBlue },
//       ltp: { content: 25, styles: aligh },
//       m2m: { content: 25, styles: textBlue }
//     }]
//   }
// ];

// headerArrayModify(responseData, removeColumn)
let bodyArrayModify = (data, name) => {
  return data.map((item, index) => {
    let newObj = {};
    let finalObj = {};
    Object.entries(_.omit(item, [name, "id", "userId"])).forEach(
      (itemData, i) => {
        let newObj1 = {};
        newObj1.content = itemData[1];
        if (i !== 0) {
          newObj1.styles = aligh;
        }
        if (itemData[0] == "netQty" || itemData[0] == "mtm") {
          itemData[1] >= 0
            ? (newObj1.styles = textBlue)
            : (newObj1.styles = textRed);
        }
        finalObj[itemData[0]] = newObj1;
      }
    );
    newObj.data = [finalObj];
    newObj.name = item[name];
    return newObj;
  });
};

const headerArrayModify = (data, removeColumn) => {
  let removeColumnFinal = Object.entries(data[0].data[0]).filter((item) => {
    if (!removeColumn.includes(item[0])) {
      return item[0];
    }
  });
  let finalArray = [];
  removeColumnFinal.forEach((dataEntity, i) => {
    finalArray.push({
      dataKey: dataEntity[0],
      title: dataEntity[0],
      styles: i !== 0 ? aligh : "",
    });
  });
  return finalArray;
};

export const getTradeReport = createAsyncThunk(
  "tradeReport",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { reportMode = "pdf", valan = {}, segment = {} } = paramData || {};
      let { userId } = getSessionData();
      let newFinalObject = {};
      let userIdArr = [];
      let reportTitle = "";
      let dateOfReport = "";
      let fromToLabel = "";
      let valanLabel = `${valan.label} (${segment.label ? segment.label : "-"
        })`;
      if (paramData.ledger) {
        userIdArr.push(paramData.userId);
        newFinalObject.userId = userIdArr;
        newFinalObject.reportFor = 1;
        newFinalObject.reportType = 2;
        reportTitle = "Script Wise Bill";
        dateOfReport = `${paramData.document} ()`;
        fromToLabel = `From ${moment(paramData.valanStartDate).format(
          "DD-MM-YYYY"
        )} To ${moment(paramData.valanEndDate).format("DD-MM-YYYY")}`;
      } else {
        reportTitle = paramData.report.label;
        dateOfReport = `${paramData.valan.label} (${paramData.segment.label ? paramData.segment.label : "-"
          })`;
        fromToLabel = `From ${moment(paramData.valanStartDate).format(
          "DD-MM-YYYY"
        )} To ${moment(paramData.valanEndDate).format("DD-MM-YYYY")}`;
        userIdArr.push(userId);
        newFinalObject.userId =
          paramData.accountSettingType !== "3"
            ? paramData.userId?.length > 0 ? paramData.userId.map((item) => Number(item.value)) : userIdArr
            : userIdArr;
        newFinalObject.reportFor = paramData.report
          ? paramData.report.value
          : 0;
        newFinalObject.reportType = paramData.reportType
          ? paramData.reportType
          : 0;
      }
      newFinalObject.reportGeneratedBy = userId;
      newFinalObject.valanId = paramData.valan ? paramData.valan.value : 0;
      newFinalObject.subBrokerId = paramData.subBroker
        ? paramData.subBroker.value
        : 0;
      newFinalObject.segmentId = paramData.segment
        ? paramData.segment.value
        : 0;
      newFinalObject.symbol = paramData.symbol ? paramData.symbol.value : "";
      newFinalObject.dateFilterType =
        paramData.dateFilter && paramData.dateFilter == "All" ? 1 : 2;
      newFinalObject.rateSource = paramData.liveRateSource
        ? paramData.liveRateSource
        : 0;
      newFinalObject.isExpiryDate = paramData.isExpiryDate;
      newFinalObject.expiryDate = paramData.expiryDate;
      newFinalObject.orderBy = paramData.orderBy ? paramData.orderBy.value : 0;
      newFinalObject.endDate = paramData.valanEndDate;
      newFinalObject.startDate = paramData.valanStartDate;

      // newFinalObject.reportType = 0;
      // newFinalObject.reportFor = 0;
      // newFinalObject.valanId = 68;
      // newFinalObject.userId = 229;
      // newFinalObject.subBrokerId = 0;
      // newFinalObject.segmentId = 0;
      // newFinalObject.symbol = "";
      // newFinalObject.dateFilterType = 0;
      // newFinalObject.rateSource = 0;
      // newFinalObject.isExpiryDate = true;
      // newFinalObject.expiryDate = "2022-04-26T12:29:42.801Z";
      // newFinalObject.reportGeneratedBy = 0;
      // newFinalObject.orderBy = "";
      axios
        .post(`reports/trade-report/get-trade-report`, newFinalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            let responseData = data.data;
            if (responseData.length > 0) {
              let columnData;
              let accountType = paramData.accountSettingType;
              let accountTypeLabel =
                accountType === "1"
                  ? "CUSTOMER"
                  : accountType === "2"
                    ? "AUTHORITY"
                    : "SELF";
              let labelName = "";
              if (paramData.ledger) {
                labelName = "21";
              } else {
                labelName = paramData.reportType + paramData.report.value;
              }
              if (reportMode === "pdf") {
                if (labelName === "11") {
                  // removeColumn = ["userId", "clientName", "beAvg"];
                  columnData = [
                    {
                      title: "Script Name",
                      dataKey: "script",
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "totalBuyQty",
                      styles: aligh,
                    },
                    {
                      title: "Buy Avg.",
                      dataKey: "buyAvgPrice",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "totalSellQty",
                      styles: aligh,
                    },
                    {
                      title: "Sell Avg.",
                      dataKey: "sellAvgPrice",
                      styles: aligh,
                    },
                    {
                      title: "Pos Qty",
                      dataKey: "netQty",
                      styles: aligh,
                    },
                    {
                      title: "LTP",
                      dataKey: "ltp",
                      styles: aligh,
                    },
                    {
                      title: "M2M",
                      dataKey: "mtm",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "12") {
                  // removeColumn = ["totalBuyQty", "buyAvgPrice", "totalSellQty", "sellAvgPrice", "clientName", "userId"];
                  columnData = [
                    {
                      title: "Script Name",
                      dataKey: "script",
                    },
                    {
                      title: "Pos Qty",
                      dataKey: "netQty",
                      styles: aligh,
                    },
                    {
                      title: "B.E. Avg",
                      dataKey: "beAvg",
                      styles: aligh,
                    },
                    {
                      title: "Value",
                      dataKey: "value",
                      styles: aligh,
                    },
                    {
                      title: "LTP",
                      dataKey: "ltp",
                      styles: aligh,
                    },
                    {
                      title: "M2M",
                      dataKey: "mtm",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "13") {
                  columnData = [
                    {
                      title: "Client Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "totalBuyQty",
                      styles: aligh,
                    },
                    {
                      title: "Buy Avg.",
                      dataKey: "buyAvgPrice",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "totalSellQty",
                      styles: aligh,
                    },
                    {
                      title: "Sell Avg.",
                      dataKey: "sellAvgPrice",
                      styles: aligh,
                    },
                    {
                      title: "Pos Qty",
                      dataKey: "netQty",
                      styles: aligh,
                    },
                    {
                      title: "LTP",
                      dataKey: "ltp",
                      styles: aligh,
                    },
                    {
                      title: "M2M",
                      dataKey: "mtm",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "14") {
                  columnData = [
                    {
                      title: "Script Name",
                      dataKey: "script",
                    },
                    {
                      title: "Total Buy",
                      dataKey: "totalBuy",
                      styles: aligh,
                    },
                    {
                      title: "Total Sell",
                      dataKey: "totalSell",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "15") {
                  columnData = [
                    {
                      title: "Client Code",
                      dataKey: "accountCode",
                    },
                    {
                      title: "Client Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Total Buy",
                      dataKey: "totalBuy",
                      styles: aligh,
                    },
                    {
                      title: "Total Sell",
                      dataKey: "totalSell",
                      styles: aligh,
                    },
                    {
                      title: "Net Hodiyu",
                      dataKey: "netHodiyu",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "21") {
                  columnData = [
                    {
                      title: "Date",
                      dataKey: "tradeDate",
                    },
                    {
                      title: "Type",
                      dataKey: "tradeFrom",
                      styles: aligh,
                    },
                    {
                      title: "Lot",
                      dataKey: "lot",
                      styles: aligh,
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "buyQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "sellQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Rate",
                      dataKey: "rate",
                      styles: aligh,
                    },
                    {
                      title: "Net Rate",
                      dataKey: "netRate",
                      styles: aligh,
                    },
                    {
                      title: "Net Amount",
                      dataKey: "netAmount",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "22" || labelName == "24") {
                  columnData = [
                    {
                      title: "Type",
                      dataKey: "tradeFrom_b",
                      styles: aligh,
                    },
                    {
                      title: "Lot",
                      dataKey: "lot_b",
                      styles: aligh,
                    },
                    {
                      title: "Qty",
                      dataKey: "buyQuantity_b",
                      styles: aligh,
                    },
                    {
                      title: "T. Rate",
                      dataKey: "rate_b",
                      styles: aligh,
                    },
                    {
                      title: "N. Rate",
                      dataKey: "netRate_b",
                      styles: aligh,
                    },
                    {
                      title: "Net Amt",
                      dataKey: "netAmount_b",
                      styles: aligh,
                    },
                    {
                      title: "Date",
                      dataKey: "tradeDate",
                      styles: { halign: "center" },
                    },
                    {
                      title: "Type",
                      dataKey: "tradeFrom_s",
                      styles: aligh,
                    },
                    {
                      title: "Lot",
                      dataKey: "lot_s",
                      styles: aligh,
                    },
                    {
                      title: "Qty",
                      dataKey: "sellQuantity_s",
                      styles: aligh,
                    },
                    {
                      title: "T. Rate",
                      dataKey: "rate_s",
                      styles: aligh,
                    },
                    {
                      title: "N. Rate",
                      dataKey: "netRate_s",
                      styles: aligh,
                    },
                    {
                      title: "Net Amt",
                      dataKey: "netAmount_s",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "25") {
                  columnData = [
                    {
                      title: "Date",
                      dataKey: "tradeDate",
                    },
                    {
                      title: "Type",
                      dataKey: "tradeFrom",
                      styles: aligh,
                    },
                    {
                      title: "Lot",
                      dataKey: "lot",
                      styles: aligh,
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "buyQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "sellQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Rate",
                      dataKey: "rate",
                      styles: aligh,
                    },
                    {
                      title: "Net Rate",
                      dataKey: "netRate",
                      styles: aligh,
                    },
                    {
                      title: "Net Amount",
                      dataKey: "netAmount",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "26") {
                  columnData = [
                    {
                      title: "Date",
                      dataKey: "tradeDate",
                    },
                    {
                      title: "Type",
                      dataKey: "tradeFrom",
                      styles: aligh,
                    },
                    {
                      title: "Lot",
                      dataKey: "lot",
                      styles: aligh,
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "buyQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "sellQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Net Rate",
                      dataKey: "netRate",
                      styles: aligh,
                    },
                    {
                      title: "Net Amount",
                      dataKey: "netAmount",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "27" || labelName == "28") {
                  columnData = [
                    {
                      title: "Account Code",
                      dataKey: "accountCode",
                    },
                    {
                      title: "Client Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Debit",
                      dataKey: "debit",
                      styles: aligh,
                    },
                    {
                      title: "Credit",
                      dataKey: "credit",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "213") {
                  columnData = [
                    {
                      title: "Account Code",
                      dataKey: "accountCode",
                    },
                    {
                      title: "Client Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Debit",
                      dataKey: "debit",
                      styles: aligh,
                    },
                    {
                      title: "Credit",
                      dataKey: "credit",
                      styles: aligh,
                    },
                  ];
                } else if (billSimpleData.includes(labelName)) {
                  columnData = [
                    {
                      title: "Date",
                      dataKey: "tradeDate",
                    },
                    {
                      title: "Type",
                      dataKey: "tradeFrom",
                      styles: aligh,
                    },
                    {
                      title: "Lot",
                      dataKey: "lot",
                      styles: aligh,
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "buyQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "sellQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Rate",
                      dataKey: "rate",
                      styles: aligh,
                    },
                    {
                      title: "Net Rate",
                      dataKey: "netRate",
                      styles: aligh,
                    },
                    {
                      title: "Net Amount",
                      dataKey: "netAmount",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "31") {
                  if (paramData.accountSettingType == 3) {
                    labelName = "313";
                  }
                  if (paramData.accountSettingType == 2) {
                    labelName = "312";
                  }

                  // removeColumn = ["userId", "clientName", "beAvg"];
                  columnData = [
                    {
                      title: "Sr.No",
                      dataKey: "srNo",
                    },
                    {
                      title: "Authority",
                      dataKey: "authorityName",
                    },
                    {
                      title: "Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Gross Amt",
                      dataKey: "grossAmt",
                      styles: aligh,
                    },
                    {
                      title: "Cl Brok.",
                      dataKey: "clientBrok",
                      styles: aligh,
                    },
                    {
                      title: "Bill Amt.",
                      dataKey: "billAmt",
                      styles: aligh,
                    },
                    {
                      title: "Sb Brok.",
                      dataKey: "sbBrok",
                      styles: aligh,
                    },
                    {
                      title: "Partner Brok.",
                      dataKey: "partnershipBrok",
                      styles: aligh,
                    },
                    {
                      title: "Self Brok",
                      dataKey: "selfBrok",
                      styles: aligh,
                    },
                    {
                      title: "Sb Amt.",
                      dataKey: "sbAmt",
                      styles: aligh,
                    },
                    {
                      title: "Partner Amt.",
                      dataKey: "partnershipAmt",
                      styles: aligh,
                    },
                    {
                      title: "Self Amt.",
                      dataKey: "selfAmt",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "32") {
                  if (paramData.accountSettingType == 3) {
                    labelName = "323";
                  }
                  columnData = [
                    {
                      title: "Authority",
                      dataKey: "authorityName",
                    },
                    {
                      title: "Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "MTM Amt.",
                      dataKey: "billAmt",
                      styles: aligh,
                    },
                    {
                      title: "Bro Amt.",
                      dataKey: "clientBrok",
                      styles: aligh,
                    },
                    {
                      title: "Total Amt",
                      dataKey: "grossAmt",
                      styles: aligh,
                    },
                    {
                      title: "Sb Bro.",
                      dataKey: "sbBrok",
                      styles: aligh,
                    },
                    {
                      title: "Share %",
                      dataKey: "sharingPercentage",
                      styles: aligh,
                    },
                    {
                      title: "Br Share.",
                      dataKey: "selfBrok",
                      styles: aligh,
                    },
                    {
                      title: "Net Amt.",
                      dataKey: "selfAmt",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "41") {
                  columnData = [
                    {
                      title: "Script",
                      dataKey: "script",
                    },
                    {
                      title: "Trade Time",
                      dataKey: "tradeTime",
                    },
                    {
                      title: "Lot",
                      dataKey: "lot",
                      styles: aligh,
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "buyQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "sellQuantity",
                      styles: aligh,
                    },
                    {
                      title: "Net Rate",
                      dataKey: "netRate",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "42") {
                  columnData = [
                    {
                      title: "Script",
                      dataKey: "script",
                    },
                    {
                      title: "Gross Buy",
                      dataKey: "grossBuy",
                      styles: aligh,
                    },
                    {
                      title: "Gross Sell",
                      dataKey: "grossSell",
                      styles: aligh,
                    },
                    {
                      title: "Net Buy",
                      dataKey: "netBuy",
                      styles: aligh,
                    },
                    {
                      title: "Net Sell",
                      dataKey: "netSell",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "43") {
                  columnData = [
                    {
                      title: "Client Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Gross Buy",
                      dataKey: "grossBuy",
                      styles: aligh,
                    },
                    {
                      title: "Gross Sell",
                      dataKey: "grossSell",
                      styles: aligh,
                    },
                    {
                      title: "Gross Total",
                      dataKey: "netBuy",
                      styles: aligh,
                    },
                    {
                      title: "Net Total",
                      dataKey: "netSell",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "51" || labelName == "52") {
                  if (labelName == "52") {
                    columnData = [
                      {
                        title: "Client Code",
                        dataKey: "clientCode",
                      },
                      {
                        title: "Name",
                        dataKey: "clientName",
                      },
                      {
                        title: "Brokerage",
                        dataKey: "clientBrok",
                        styles: aligh,
                      },
                      {
                        title: "Sub Broker Brok.",
                        dataKey: "sbBrok",
                        styles: aligh,
                      },
                      {
                        title: "Final Brokerage",
                        dataKey: "finalBrok",
                        styles: aligh,
                      },
                    ];
                  } else {
                    columnData = [
                      {
                        title: "Client Code",
                        dataKey: "clientCode",
                      },
                      {
                        title: "Name",
                        dataKey: "clientName",
                      },
                      {
                        title: "Brokerage",
                        dataKey: "clientBrok",
                        styles: aligh,
                      },
                      {
                        title: "Sub Broker Brok.",
                        dataKey: "sbBrok",
                        styles: aligh,
                      },
                    ];
                  }
                } else if (labelName == "53") {
                  columnData = [
                    {
                      title: "Client Code",
                      dataKey: "clientCode",
                    },
                    {
                      title: "Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Sub Broker Brok.",
                      dataKey: "sbBrok",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "54") {
                  columnData = [
                    {
                      title: "Client Code",
                      dataKey: "clientCode",
                    },
                    {
                      title: "Name",
                      dataKey: "clientName",
                    },
                    {
                      title: "Brokerage",
                      dataKey: "clientBrok",
                      styles: aligh,
                    },
                    {
                      title: "Sub Broker Brok.",
                      dataKey: "sbBrok",
                      styles: aligh,
                    },
                    {
                      title: "Partner Sb.",
                      dataKey: "partnershipBrok",
                      styles: aligh,
                    },
                    {
                      title: "Self Brokerage",
                      dataKey: "selfBrok",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "61") {
                  // removeColumn = ["userId", "clientName", "beAvg"];
                  columnData = [
                    {
                      title: "Script Name",
                      dataKey: "script",
                    },
                    {
                      title: "Buy Qty",
                      dataKey: "totalBuyQty",
                      styles: aligh,
                    },
                    {
                      title: "Buy Avg.",
                      dataKey: "buyAvgPrice",
                      styles: aligh,
                    },
                    {
                      title: "Sell Qty",
                      dataKey: "totalSellQty",
                      styles: aligh,
                    },
                    {
                      title: "Sell Avg",
                      dataKey: "sellAvgPrice",
                      styles: aligh,
                    },
                    {
                      title: "Pos Qty",
                      dataKey: "netQty",
                      styles: aligh,
                    },
                    {
                      title: "LTP",
                      dataKey: "ltp",
                      styles: aligh,
                    },
                    {
                      title: "M2M",
                      dataKey: "mtm",
                      styles: aligh,
                    },
                  ];
                } else if (labelName == "62") {
                  if (paramData.accountSettingType == 3) {
                    labelName = "623";
                  }
                  // removeColumn = ["userId", "clientName", "beAvg"];
                  columnData = [
                    {
                      title: "Script Name",
                      dataKey: "script",
                    },
                    {
                      title: "Pos Qty",
                      dataKey: "netQty",
                      styles: aligh,
                    },
                    {
                      title: "B.E. Avg.",
                      dataKey: "beAvg",
                      styles: aligh,
                    },
                    {
                      title: "Value",
                      dataKey: "value",
                      styles: aligh,
                    },
                    {
                      title: "LTP",
                      dataKey: "ltp",
                      styles: aligh,
                    },
                    {
                      title: "M2M",
                      dataKey: "mtm",
                      styles: aligh,
                    },
                  ];
                }
                let exportData = {
                  reportType: labelName,
                  FileName: "Trade Report",
                  title: reportTitle,
                  date: dateOfReport,
                  fromTo: fromToLabel,
                  arrayName: responseData,
                  exportColumn: columnData,
                  rowPageBreak: true,
                  theme: "grid",
                  headStyles: bgGray,
                  footerStyle: footerStyle,
                  footerColorBlue: footerBlue,
                  footerColorRed: footerRed,
                  showHead: "showHead",
                };
                if (paramData?.userRole) {
                  exportData = { ...exportData, userRole: paramData.userRole };
                }
                generatePDF(exportData);
              } else if (reportMode === "excel") {
                generateExcel(
                  responseData,
                  labelName,
                  reportTitle,
                  valanLabel,
                  fromToLabel,
                  accountTypeLabel
                );
              }
              resolve(data);
              dispatch(setTradeReportLoading(false));
            } else {
              dispatch(
                showMessage({
                  message: "No data Found",
                })
              );
              dispatch(setTradeReportLoading(false));
            }
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
            dispatch(setTradeReportLoading(false));
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setSortLedgerReport: (state, action) => {
      state.sortReportLedgerArr = action.payload;
    },
    setOTDReportLogList: (state, action) => {
      state.OTDReportLogList = action.payload;
    },
    setOTRejectionLogList: (state, action) => {
      state.OTRejectionLogList = action.payload;
    },
    setOTDReportLogCurrentPage: (state, action) => {
      state.OTDReportLogCurrentPage = action.payload;
    },
    setOTDRejectionLogCurrentPage: (state, action) => {
      state.OTDRejectionLogCurrentPage = action.payload;
    },
    setOTDReportLogCurrentSearchText: (state, action) => {
      state.OTDReportLogCurrentSearchText = action.payload;
    },
    setStartApiCallOTDRejectionLogList: (state, action) => {
      state.startApiCallOTDRejectionLogList = action.payload;
    },
    setStartApiCallOTDReportLogList: (state, action) => {
      state.startApiCallOTDReportLogList = action.payload;
    },
    setOTDReportLogTotal: (state, action) => {
      state.OTDReportLogTotal = action.payload;
    },
    setFilterDataUpdateLog: (state, action) => {
      state.filterDataUpdateLog = action.payload;
    },
    setIsFilterDataUpdateLog: (state, action) => {
      state.isFilterDataUpdateLog = action.payload;
    },
    setFilterRejectionLogData: (state, action) => {
      state.filterRejectionLogData = action.payload;
    },
    setIsFilterRejectionLogData: (state, action) => {
      state.isFilterRejectionLogData = action.payload;
    },
    setOTDRejectionLogCurrentSearchText: (state, action) => {
      state.OTDRejectionLogCurrentSearchText = action.payload;
    },
    setIsReportGet: (state, action) => {
      state.isReportGet = action.payload;
    },
    setTradeReportFilterValue: (state, action) => {
      state.tradeReportFilterValue = action.payload;
    },
    setOTDRejectionLogTotal: (state, action) => {
      state.OTDRejectionLogTotal = action.payload;
    },
    setTradeReportLoading: (state, action) => {
      state.tradeReportLoading = action.payload;
    },
    setTrialBalanceReportLoading: (state, action) => {
      state.trialBalanceReportLoading = action.payload;
    },
    setOpenModalTradeData: (state, action) => {
      state.OpenModalTradeData = action.payload;
    },
    setOpenTradeData: (state, action) => {
      state.openTradeData = action.payload;
    },
  },
  extraReducers: {
    [getTradesbySymbolList.pending]: (state, action) => {
      state.tradesbySymbolLoading = true;
    },
    [getTradesbySymbolList.rejected]: (state, action) => {
      state.tradesbySymbolList = [];
      state.tradesbySymbolLoading = false;
    },
    [getTradesbySymbolList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.tradesbySymbolList = [
          ...state.tradesbySymbolList,
          ...action.payload.data.records,
        ];
        state.totalTradesbySymbol = action.payload.data.totalRecords;
      } else {
        state.tradesbySymbolList = action.payload.data.records;
        state.totalTradesbySymbol = action.payload.data.totalRecords;
      }
      state.summaryLoading = false;
    },
    [getTrialBalance.pending]: (state, action) => {
      state.trialBalanceReportLoading = true;
    },
    [getTrialBalance.rejected]: (state, action) => {
      state.trialBalanceReportLoading = false;
    },
    [getTrialBalance.fulfilled]: (state, action) => {
      state.trialBalanceReportLoading = false;
    },
    [getTradeReport.pending]: (state, action) => {
      state.tradeReportLoading = true;
    },
    [getTradeReport.rejected]: (state, action) => {
      state.tradeReportLoading = false;
    },
    [getTradeReport.fulfilled]: (state, action) => {
      state.tradeReportLoading = false;
    },
    //Ledger Report
    [getLedgerReportList.pending]: (state, action) => {
      state.ledgerReportList = [];
      state.ledgerReportLoading = true;
    },
    [getLedgerReportList.rejected]: (state, action) => {
      state.ledgerReportList = [];
      state.ledgerReportLoading = false;
    },
    [getLedgerReportList.fulfilled]: (state, action) => {
      state.ledgerReportList = action?.payload || [];
      state.ledgerReportLoading = false;
    },
    //order/ Trade Edit / Delete Log
    [getOTDReportLogList.pending]: (state, action) => {
      state.OTDReportLogLoading = true;
      state.startApiCallOTDReportLogList = false;
    },
    [getOTDReportLogList.rejected]: (state, action) => {
      state.OTDReportLogList = [];
      state.OTDReportLogTotal = 0;
      state.OTDReportLogLoading = false;
      state.startApiCallOTDReportLogList = false;
    },
    [getOTDReportLogList.fulfilled]: (state, action) => {
      state.OTDReportLogLoading = false;
      state.OTDReportLogTotal = action.payload.data.totalRecords;
      if (action.payload.appendData) {
        state.OTDReportLogList = [
          ...state.OTDReportLogList,
          ...action.payload.data.records,
        ];
      } else {
        state.OTDReportLogList = action.payload.data.records;
      }
      state.startApiCallOTDReportLogList = false;
    },
    //order/ Trade Edit Rejection Log
    [getOTRejectionLogList.pending]: (state, action) => {
      state.OTRejectionLogLoading = true;
      state.startApiCallOTDRejectionLogList = false;
    },
    [getOTRejectionLogList.rejected]: (state, action) => {
      state.OTRejectionLogList = [];
      state.OTDRejectionLogTotal = 0;
      state.OTRejectionLogLoading = false;
      state.startApiCallOTDRejectionLogList = false;
    },
    [getOTRejectionLogList.fulfilled]: (state, action) => {
      state.OTDRejectionLogTotal = action.payload.data.totalRecords;
      if (action.payload.appendData) {
        state.OTRejectionLogList = [
          ...state.OTRejectionLogList,
          ...action.payload.data.records,
        ];
      } else {
        state.OTRejectionLogList = action.payload.data.records;
      }
      state.OTRejectionLogLoading = false;
      state.startApiCallOTDRejectionLogList = false;
    },
  },
});

export const {
  setSortLedgerReport,
  setOTDReportLogCurrentPage,
  setFilterDataUpdateLog,
  setIsFilterDataUpdateLog,
  setOTDRejectionLogCurrentPage,
  setFilterRejectionLogData,
  setIsFilterRejectionLogData,
  setOTRejectionLogList,
  setOTDRejectionLogCurrentSearchText,
  setOTDReportLogCurrentSearchText,
  setStartApiCallOTDRejectionLogList,
  setOTDReportLogList,
  setIsReportGet,
  setTradeReportFilterValue,
  setOTDRejectionLogTotal,
  setOTDReportLogTotal,
  setTradeReportLoading,
  setTrialBalanceReportLoading,
  setOpenModalTradeData,
  tradesbySymbolList,
  totalTradesbySymbol,
  tradesbySymbolLoading,
  setOpenTradeData,

} = reportSlice.actions;

export default reportSlice.reducer;
